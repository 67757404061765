import { RouteObject } from 'react-router-dom'

import { RouteNames } from 'const/navigation'
import ApiKeyPage from './containers/api-keys-list-view'

const routes: RouteObject[] = [
  {
    path: RouteNames.ApiKeys,
    element: <ApiKeyPage />,
  },
]

export default routes
