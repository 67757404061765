export interface JSONRPCRequest<T> {
  method: string
  params: [T]
}

export interface JSONRPCResponse<T> {
  result: T
}

export enum JSONRPCMethods {
  LOGIN = 'login',
  CREATE_ACCOUNT = 'createAccount',
  GOOGLE_SSO_LOGIN = 'googleSSOLogin',
  REFRESH_TOKEN = 'refreshToken',
  CHECK_EMAIL = 'checkEmail',
  RESET_PASSWORD = 'resetPassword',
  VERIFY_ACCOUNT = 'verifyAccount',
  SETUP_NEW_PASSWORD = 'setupNewPassword',
  GET_ACCOUNT = 'getAccount',
  GET_INDEX = 'getIndex',
  GET_NAMESPACES = 'getNamespace',
  CREATE_INDEX = 'createIndex',
  INSERT = 'insert',
  QUERY = 'query',
  LIST_INDEXES = 'listIndexes',
}
