import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import { Banner } from 'components/banner'
import { RouteNames } from 'const/navigation'
import { IndexDetailHeader } from 'modules/indexes/components/index-detail-header'
import { IndexDetailContext } from 'modules/indexes/context'
import { useIndexStore } from 'modules/indexes/store'

function IndexDetailPage() {
  const params = useParams()
  const navigate = useNavigate()

  const { getIndexByName, loading: indexLoading, fetchIndexes } = useIndexStore()
  const indexDetail = getIndexByName(params.indexName!)

  useEffect(() => {
    fetchIndexes()
  }, [])

  if (indexLoading) {
    return <Banner type="loading" message="Loading..." />
  }

  if (!indexDetail) {
    return (
      <Banner
        type="error"
        message="Index not found"
        actionBtns={[{ label: 'Go Dashboard', onClick: () => navigate(RouteNames.Dashboard) }]}
      />
    )
  }

  return (
    <IndexDetailContext.Provider value={indexDetail}>
      <div className="mb-4">
        <IndexDetailHeader />
      </div>

      <Outlet />
    </IndexDetailContext.Provider>
  )
}

export default IndexDetailPage
