import React, { forwardRef } from 'react'
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom'

export const Link = forwardRef(function Link(
  props: LinkProps & React.ComponentPropsWithoutRef<'a'>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  const { href, to, ...rest } = props
  return (
    <ReactRouterLink {...rest} to={href ?? to ?? '/404'} ref={ref}>
      {props.children}
    </ReactRouterLink>
  )
})
