import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { CreateIndexFormSchema } from '../schema'

import { Button } from 'components/catalyst/button'
import { Input } from 'components/catalyst/input'
import { Select } from 'components/catalyst/select'
import { useNotificationStore } from 'components/common'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { RouteNames } from 'const/navigation'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { createIndexApi } from '../services'
import { useIndexStore } from '../store'

const formSchema = CreateIndexFormSchema

const formFields = [
  { name: 'indexName', label: 'Index Name', autoFocus: true },
  { options: ['cosine'], name: 'similarityFunction', label: 'Similarity Function' },
  { options: ['gcp'], name: 'cloud', label: 'Cloud' },
  { options: ['us-east1'], name: 'region', label: 'Region' },
]

export function CreateIndexForm({ onCancel }: { onCancel?: () => void }) {
  const [loading, setLoading] = useState(false)
  const { setMessage } = useNotificationStore()
  const { fetchIndexes } = useIndexStore()
  const navigate = useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      indexName: '',
      similarityFunction: 'cosine',
      cloud: 'gcp',
      region: 'us-east1',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setLoading(true)
      setMessage(`<b>${values.indexName}</b> is creating now`, { type: 'loading' })

      const response = await createIndexApi(values)

      setMessage(`<b>${values.indexName}</b> has been created. You are being redirected..`, { type: 'success' })
      onCancel?.()
      setTimeout(() => {
        fetchIndexes(true)
        navigate(RouteNames.IndexDetailConsole.get(response.data.indexName, 'insert'))
      }, 750)
    } catch (e: any) {
      setMessage(e.message, { type: 'error' })
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  function getInputElement(formField: any, field: any) {
    if (formField.options) {
      return (
        <Select disabled={loading} {...field}>
          {formField.options.map((option: string) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      )
    }

    return <Input disabled={loading} {...field} autoFocus={formField.autoFocus} />
  }

  function renderForm() {
    return formFields.map((formField) => (
      <FormField
        key={formField.name}
        control={form.control}
        name={formField.name as any}
        render={({ field }) => {
          const inputElement = getInputElement(formField, field)

          return (
            <FormItem>
              <FormLabel>{formField.label}</FormLabel>

              <FormControl>{inputElement}</FormControl>

              <FormMessage className="text-xs" />
            </FormItem>
          )
        }}
      />
    ))
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 sm:max-w-md">
        {renderForm()}

        <div className="flex justify-end gap-4">
          {onCancel && (
            <Button plain onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button color="amber" type="submit" loading={loading} disabled={loading}>
            Create Index
          </Button>
        </div>
      </form>
    </Form>
  )
}
