import { jsonRpcApiClient } from 'lib'
import { JSONRPCMethods } from 'types/json-rpc.types'
import { CheckEmailRequest, CheckEmailResponse, LoginRequest, LoginResponse, RefreshTokenRequest } from '../types'

export function checkEmailApi(values: CheckEmailRequest) {
  return jsonRpcApiClient<CheckEmailRequest, CheckEmailResponse>(JSONRPCMethods.CHECK_EMAIL, values)
}

export function loginApi(values: LoginRequest) {
  return jsonRpcApiClient<LoginRequest, LoginResponse>(JSONRPCMethods.LOGIN, values)
}

export function refreshTokenApi(values: RefreshTokenRequest) {
  return jsonRpcApiClient<RefreshTokenRequest, LoginResponse>(JSONRPCMethods.REFRESH_TOKEN, values)
}
