export const ShellCurlSnippet = `curl --request POST \\
  --url {{url}} \\
  --header 'Accept: application/json' \\
  --header 'x-api-key: {{apiKey}}' \\
  --header 'Content-Type: application/json' \\
  --data '{{body}}'`

export const ShellHTTPieSnippet = `echo '{{body}}' |  \\
  http POST {{url}} \\
  Accept:application/json \\
  x-api-key:'{{apiKey}}' \\
  Content-Type:application/json`

export const ShellWgetSnippet = `wget --quiet \\
  --method POST \\
  --header 'x-api-key: {{apiKey}}' \\
  --header 'Accept: application/json' \\
  --header 'Content-Type: application/json' \\
  --body-data '{{body}}' \\
  --output-document \\
  - {{url}}`
