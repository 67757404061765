import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { useDocsPanel } from 'context/docs-provider'
import { FileCode2 } from 'lucide-react'
import { CreateIndexForm } from '../components/create-index-form'

function CreateIndexPage() {
  const { openDocsPanel } = useDocsPanel()

  function showDocs() {
    openDocsPanel('/insert-vectors/#create-a-collection')
  }

  return (
    <>
      <Heading
        actions={
          <>
            <Button onClick={showDocs} className="max-sm:w-full" outline>
              <FileCode2 size={16} /> API Docs
            </Button>
          </>
        }
        description="An index organizes and holds multiple vectors in Vectroid."
      >
        Create New Index
      </Heading>

      <CreateIndexForm />
    </>
  )
}

export default CreateIndexPage
