import { Badge } from 'components/catalyst/badge'
import { Button } from 'components/catalyst/button'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'components/ui/accordion'
import { CollectionType } from 'const'
import { useDocsPanel } from 'context/docs-provider'
import { cn } from 'lib/utils'
import { FileCode2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Collection } from '..'
import { useConsoleStore } from '../store/console-store'

type Props = {
  collectionList: { title: string; items: Collection[] }[]
}

export function ApiCollectionList(props: Props) {
  const defaultOpenAccordions = props.collectionList.map((_, index) => `index-${index}`)
  const { openDocsPanel } = useDocsPanel()
  const [searchParams, setSearchParams] = useSearchParams()

  const [activeAccordions, setActiveAccordions] = useState(defaultOpenAccordions)
  const { activeCollection, setActiveCollection } = useConsoleStore()

  function showDocs() {
    openDocsPanel('/insert-vectors/#create-a-collection')
  }

  const isActiveCollection = (type: CollectionType) => {
    return activeCollection.type === type
  }

  const handleActiveCollectionFromQueryParam = () => {
    const activeCollectionFromQueryParam = props.collectionList
      .map((collection) => collection.items)
      .flat()
      .find((item) => {
        const searchParams = new URLSearchParams(window.location.search)
        return item.type === searchParams.get('type')
      })
    const isCollectionAvailable = props.collectionList
      .map((item) => item.items)
      .flat()
      .some((item) => [activeCollectionFromQueryParam?.type /* activeCollection.type */].includes(item.type))

    if (isCollectionAvailable) {
      activeCollectionFromQueryParam && setActiveCollection(activeCollectionFromQueryParam)
    } else {
      setActiveCollection(props.collectionList[0].items[0])
    }
  }

  useEffect(() => {
    handleActiveCollectionFromQueryParam()
  }, [])

  const renderCollections = () => {
    return props.collectionList.map((collection, index) => {
      return (
        <AccordionItem value={`index-${index}`} key={`collection-${index}`} className="pl-2">
          <AccordionTrigger className="text-sm !no-underline opacity-65">{collection.title}</AccordionTrigger>
          <AccordionContent className="pl-3">
            {collection.items.map((item, index) => (
              <button
                disabled={item.disabled}
                key={`item-${index}`}
                onClick={() => {
                  searchParams.set('type', item.type)
                  setSearchParams(searchParams)
                  setActiveCollection(item)
                }}
                className={cn(
                  'flex w-full items-center gap-x-2 rounded-l-lg py-1 pl-3',
                  isActiveCollection(item.type)
                    ? 'pointer-events-none bg-zinc-200 dark:bg-zinc-950/70'
                    : 'hover:bg-zinc-100 dark:hover:bg-zinc-950/30',
                  item.disabled && 'cursor-not-allowed'
                )}
              >
                <Badge className="w-10 !bg-transparent !px-0" color={item.color as any}>
                  {item.method}
                </Badge>
                {item.label}
              </button>
            ))}
          </AccordionContent>
        </AccordionItem>
      )
    })
  }

  return (
    <section className="-ml-6 w-64 pb-6">
      <h2 className="my-2 flex select-none items-center justify-between pl-8 pr-4 text-sm font-medium dark:text-zinc-300">
        Select an API
        <Button plain className="dark:text-zinc-300" onClick={showDocs}>
          <FileCode2 size={16} />
        </Button>
      </h2>

      <Accordion type="multiple" onValueChange={(value) => setActiveAccordions(value)} value={activeAccordions}>
        {renderCollections()}
      </Accordion>
    </section>
  )
}
