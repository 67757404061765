import { useEffect, useState } from 'react'

interface ElementSize {
  width: number | undefined
  height: number | undefined
}

export function useElementSize(selector: string): ElementSize {
  const [elementSize, setElementSize] = useState<ElementSize>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    const element = document.querySelector<HTMLElement>(selector)
    if (!element) return

    const updateSize = () => {
      setElementSize({
        width: element.offsetWidth,
        height: element.offsetHeight,
      })
    }

    // Update size initially
    updateSize()

    // Add resize observer
    const observer = new ResizeObserver(updateSize)
    observer.observe(element)

    // Cleanup observer on component unmount
    return () => {
      observer.unobserve(element)
    }
  }, [selector])

  return elementSize
}
