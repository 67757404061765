import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { Account } from '../types'

type State = {
  isAuthenticated: boolean
  account: Account | null
  token: string | null
  apiKey: string | null
  refreshToken: string | null
}

type Action = {
  login: (token: string | null, refreshToken: string | null, apiKey: string | null, account: Account) => void
  logout: () => void
}

const useAuthStore = create<State & Action>()(
  devtools(
    persist(
      (set) => ({
        isAuthenticated: false,
        token: null,
        refreshToken: null,
        apiKey: null,
        account: null,

        // Actions
        login: (token: string | null, refreshToken: string | null, apiKey: string | null, account: Account) => {
          set({ token, account, apiKey, refreshToken, isAuthenticated: true })
        },
        logout: () =>
          set({
            token: null,
            refreshToken: null,
            account: null,
            apiKey: null,
            isAuthenticated: false,
          }),
      }),
      {
        name: 'auth-storage',
      }
    )
  )
)

export { useAuthStore }
