import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { appConfig } from 'const/app-config'
import { useAuthStore } from 'modules/auth'

export interface ApiResponse<T> {
  data: T
  status: number
}

interface ApiError {
  message: string
  status: number
}

const axiosClient = axios.create()

axiosClient.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {}

    const apiKey = useAuthStore?.getState()?.apiKey
    if (apiKey) {
      config.headers = config.headers || {}
      config.headers['x-api-key'] = apiKey
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * @param url {String}
 * @param method {Method}
 * @param data T
 * @param options {ApiCallOptions} - Options for the API call
 * @returns {Promise<ApiResponse<T>>}
 */
export async function apiClient<T, K>(path: string, method: string, data: T): Promise<ApiResponse<K>> {
  const config: AxiosRequestConfig<T> = {
    url: `${appConfig.API_BASE_URL}${path}`,
    method,
    data,
    headers: {
      'Content-Type': 'application/json',
    },
  }

  try {
    const response: AxiosResponse<K> = await axiosClient(config)
    return response
  } catch (error: any) {
    const status = error?.response?.status || 500
    const message = error?.response?.data?.message || 'An unexpected error occurred'
    throw { message, status } as ApiError
  }
}
