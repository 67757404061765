import { useNotificationStore } from 'components/common'
import { GLOBAL_API_PLAYGROUND_COLLECTION } from 'const/api-collection'
import { executePlaygroundCode, IndexResponseType } from 'lib/index'
import { ApiCollectionList } from 'modules/indexes/components/api-collection-list'

import { useConsoleStore } from 'modules/indexes/store/console-store'

import { useState } from 'react'
import { QueryConsole } from '../components/query-console'

function APIPlaygroundPage() {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any>(null)
  const setMessage = useNotificationStore((state) => state.setMessage)

  const { activeCollection } = useConsoleStore()

  function setResponse(data: IndexResponseType) {
    setResult(JSON.stringify(data, null, 3))
  }

  const onExecute = async (code: string) => {
    try {
      setLoading(true)

      const response = await executePlaygroundCode(code, activeCollection.endpoint!)
      response && setResponse(response.data as unknown as IndexResponseType)
    } catch (e: any) {
      console.log(e)
      setResponse(e)
      setMessage(e.message, { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-full flex-1">
      <ApiCollectionList collectionList={GLOBAL_API_PLAYGROUND_COLLECTION} />
      <QueryConsole
        dynamicVariables={{}}
        activeCollection={activeCollection}
        onExecute={onExecute}
        loading={loading}
        result={result}
      />
    </div>
  )
}

export default APIPlaygroundPage
