export const CSharpHttpClientSnippet = `using System.Net.Http.Headers;

var client = new HttpClient();
var request = new HttpRequestMessage
{
  Method = HttpMethod.Post,
  RequestUri = new Uri("{{url}}"),
  Headers =
  {
    { "x-api-key", "{{apiKey}}" },
    { "Accept", "application/json" },
  },
  Content = new StringContent("{{body}}")
  {
    Headers =
    {
      ContentType = new MediaTypeHeaderValue("text/plain")
    }
  }
};
using (var response = await client.SendAsync(request))
{
  response.EnsureSuccessStatusCode();
  var body = await response.Content.ReadAsStringAsync();
  Console.WriteLine(body);
}`

export const CSharpRestSharpSnippet = `var client = new RestClient("{{url}}");
var request = new RestRequest(Method.POST);

request.AddHeader("x-api-key", "{{apiKey}}");
request.AddHeader("Accept", "application/json");
request.AddHeader("Content-Type", "application/json");

request.AddParameter("application/json", "{{body}}", ParameterType.RequestBody);

IRestResponse response = client.Execute(request);
`
