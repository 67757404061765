import { IndexGridItemSkeleton } from 'components/skeletons'
import { useElementSize } from 'hooks/use-element-size'
import { cn } from 'lib/utils'
import { Index } from '../types'
import { IndexItemGrid } from './index-item-grid'

type Props = {
  loading: boolean
  indexes: Index[]
}

export function IndexesGridLayout(props: Props) {
  const { width: mainLayoutWidth } = useElementSize('#application-main')

  const loadingState = (
    <>
      {new Array(6).fill(null).map((index, i) => (
        <IndexGridItemSkeleton key={`IndexGridItemSkeleton-${i}`} />
      ))}
    </>
  )

  return (
    <div
      className={cn('grid grid-cols-1 gap-6', {
        'grid-cols-1': mainLayoutWidth! < 760,
        'grid-cols-3': mainLayoutWidth! > 760,
        'grid-cols-4': mainLayoutWidth! >= 1000 && mainLayoutWidth! < 1500,
        'grid-cols-5': mainLayoutWidth! >= 1500 && mainLayoutWidth! < 2000,
        'grid-cols-6': mainLayoutWidth! >= 2000,
      })}
    >
      {props.loading ? loadingState : props.indexes.map((index, i) => <IndexItemGrid index={index} key={i} />)}
    </div>
  )
}
