import { Button } from 'components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from 'components/catalyst/dialog'
import { Field, Label } from 'components/catalyst/fieldset'
import { Input } from 'components/catalyst/input'
import { useState } from 'react'

export function ApiKeyCreateDialog() {
  let [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button className="max-sm:w-full" color="amber" onClick={() => setIsOpen(true)}>
        Create New API Key
      </Button>

      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Create a New API Key</DialogTitle>
        <DialogDescription>
          This API Key can only be viewed or downloaded once. It cannot be recovered later, but you can generate new API
          keys at any time.
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>API Key Name</Label>
            <Input name="Name" placeholder="MyProject-APIKey" autoFocus />
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => setIsOpen(false)} color="amber">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
