import { createContext, useContext, useState } from 'react'

type DocsProviderProps = {
  children: React.ReactNode
}

type DocsProviderState = {
  path: string
  visible: boolean
  openDocsPanel: (path: string) => void
  setVisible: (value: boolean) => void
}

const initialState: DocsProviderState = {
  path: '/',
  visible: false,
  openDocsPanel: () => null,
  setVisible: (value: boolean) => null,
}

const DocsProviderContext = createContext<DocsProviderState>(initialState)

export function DocsPanelProvider(props: DocsProviderProps) {
  const [visible, setVisible] = useState<boolean>(false)
  const [path, setPath] = useState('')

  const value = {
    visible,
    path,
    openDocsPanel: (_path: string) => {
      setVisible(true)
      setPath(_path)
    },
    setVisible: (value: boolean) => {
      setVisible(value)
      !value && setPath('')
    },
  }

  return <DocsProviderContext.Provider value={value}>{props.children}</DocsProviderContext.Provider>
}

export const useDocsPanel = () => {
  const context = useContext(DocsProviderContext)

  if (context === undefined) throw new Error('useDocs must be used within a DocsProvider')

  return context
}
