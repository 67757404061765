import { CollectionDynamicVariables } from 'modules/indexes'

/**
 * This function replaces the variables in the text with the values provided in the variables object
 *
 * @param text
 * @param variables
 * @returns {String}
 */
export function replaceStaticVariables(text: string, variables: CollectionDynamicVariables): string {
  return text.replace(/{{\s*([^\s{}]+)\s*}}/g, (_: any, variable: keyof CollectionDynamicVariables) => {
    const value = variables?.[variable]

    if (variable === 'vector') {
      return JSON.stringify(value?.split(',').map((value: string) => +value) ?? [])
    }

    // Check if the variable exists in the object, if not return an empty string
    return value ?? ''
  })
}
