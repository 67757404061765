import React from 'react'
import { Link } from 'react-router-dom'

interface BreadcrumbProps {
  breadcrumb: { label: string; path: string }
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb }) => {
  return (
    <Link to={breadcrumb.path} className="flex items-center text-sm text-zinc-400 hover:text-zinc-500">
      {breadcrumb.label}
    </Link>
  )
}

export default Breadcrumb
