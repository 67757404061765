import { Avatar } from 'components/catalyst/avatar'
import { Dropdown, DropdownButton } from 'components/catalyst/dropdown'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from 'components/catalyst/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarDivider,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from 'components/catalyst/sidebar'
import { SidebarLayout } from 'components/catalyst/sidebar-layout'
import { RouteNames } from 'const/navigation'
import { SidebarMenuItems } from 'const/sidebar'
import { useTheme } from 'context/theme-provider'
import { getInitials } from 'lib/utils'
import { HelpCircle as QuestionMarkCircleIcon, MenuIcon, SparklesIcon } from 'lucide-react'
import { useAuthStore } from 'modules/auth'
import PendingVerificationPage from 'modules/auth/pages/pending-verification'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { AuthLayout } from './auth-layout'
import { Button } from './catalyst/button'
import { SidebarHeader, SidebarSpacer } from './catalyst/sidebar'
import { AccountDropdownMenu } from './catalyst/sidebar-layout'
import { Logo } from './logo'

function ApplicationLayout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation()
  const { sidebarExpanded, setSidebarExpanded } = useTheme()
  const account = useAuthStore((state) => state.account)

  function handleSideNavToggle() {
    setSidebarExpanded(!sidebarExpanded)
  }

  const renderMenuItems = () => {
    return SidebarMenuItems.map((menuItems, index) => {
      // @ts-ignore
      if (menuItems?.spacer) {
        return <SidebarSpacer key={`SidebarDivider-${index}`} />
      }

      return (
        <div key={`SidebarSection-${index}`}>
          <SidebarSection>
            {/*// @ts-ignore*/}
            {menuItems!.map((menuItem) => (
              <SidebarItem href={menuItem.href} current={pathname === menuItem.href} key={menuItem.text}>
                {menuItem.icon}
                {sidebarExpanded && <SidebarLabel>{menuItem.text}</SidebarLabel>}
              </SidebarItem>
            ))}
          </SidebarSection>
        </div>
      )
    })
  }

  return (
    <SidebarLayout
      navbar={
        <Navbar>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar
                  src={account?.picture}
                  initials={getInitials(account?.name)}
                  className="size-10"
                  square
                  alt=""
                />
              </DropdownButton>
              <AccountDropdownMenu anchor="bottom end" />
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar className="relative z-10">
          <SidebarHeader>
            <div className="item0s-center mt-[2px] flex">
              <Button onClick={handleSideNavToggle} plain className={'mt-[1px]'}>
                <MenuIcon size={24} />
              </Button>
              <Logo />
            </div>
          </SidebarHeader>

          <SidebarBody className={'pt-5'}>
            {renderMenuItems()}

            <SidebarDivider />

            <SidebarSection>
              <SidebarItem href="#">
                <QuestionMarkCircleIcon size={16} />
                {sidebarExpanded && <SidebarLabel>Support</SidebarLabel>}
              </SidebarItem>

              <SidebarItem href="#">
                <SparklesIcon size={16} />
                {sidebarExpanded && <SidebarLabel>Changelog</SidebarLabel>}
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>
        </Sidebar>
      }
    >
      {children}
    </SidebarLayout>
  )
}

function ApplicationLayoutHOC(props: {
  children: React.ReactNode
  isAuthenticated: boolean
  isAccountVerified: boolean
}) {
  const { pathname } = useLocation()

  if (props.isAuthenticated) {
    if (pathname === RouteNames.VerifyAccount) {
      return <AuthLayout>{props.children}</AuthLayout>
    }

    if (!props.isAccountVerified) {
      return (
        <AuthLayout>
          <PendingVerificationPage />
        </AuthLayout>
      )
    }

    return <ApplicationLayout>{props.children}</ApplicationLayout>
  }

  return <AuthLayout>{props.children}</AuthLayout>
}

export default ApplicationLayoutHOC
