export const CurlSnippet = `CURL *hnd = curl_easy_init();

curl_easy_setopt(hnd, CURLOPT_CUSTOMREQUEST, "POST");
curl_easy_setopt(hnd, CURLOPT_URL, "{{url}}");

struct curl_slist *headers = NULL;
headers = curl_slist_append(headers, "x-api-key: {{apiKey}}");
headers = curl_slist_append(headers, "Accept: application/json");
headers = curl_slist_append(headers, "Content-Type: application/json");
curl_easy_setopt(hnd, CURLOPT_HTTPHEADER, headers);

curl_easy_setopt(hnd, CURLOPT_POSTFIELDS, "{{body}}");

CURLcode ret = curl_easy_perform(hnd);`
