import { createIndexApi, describeIndexApi, indexVectorApi, queryIndexApi } from 'modules/indexes/services'
import {
  CreateIndexRequest,
  CreateIndexResponse,
  DescribeIndexRequest,
  IndexVectorRequest,
  IndexVectorResponse,
  QueryIndexRequest,
  QueryIndexResponse,
} from 'modules/indexes/types'
import { ApiResponse } from './api.client'

type ResponseType = QueryIndexResponse | IndexVectorResponse | CreateIndexResponse
export type IndexResponseType = ApiResponse<ResponseType>

export function executePlaygroundCode(code: string, endpoint: string): Promise<IndexResponseType> {
  let promise: Promise<IndexResponseType>

  switch (endpoint) {
    case '/indexes': {
      const parsedCode = JSON.parse(code) as CreateIndexRequest
      promise = createIndexApi(parsedCode)
      break
    }

    case '/query': {
      const parsedCode = JSON.parse(code) as QueryIndexRequest
      promise = queryIndexApi(parsedCode)
      break
    }

    case '/describe': {
      const parsedCode = JSON.parse(code) as DescribeIndexRequest
      promise = describeIndexApi(parsedCode)
      break
    }

    case '/index': {
      const parsedCode = JSON.parse(code) as IndexVectorRequest
      promise = indexVectorApi(parsedCode)
      break
    }

    default:
      promise = Promise.reject(new Error('Invalid endpoint'))
      break
  }

  return promise
}
