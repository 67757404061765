import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { Input } from 'components/catalyst/input'
import { Alert, AlertDescription } from 'components/ui/alert'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { RouteNames } from 'const/navigation'
import { useState } from 'react'
import { ForgotPasswordFormSchema } from '../schema/forgot-password.schema'
import { resetPasswordApi } from '../services/reset-password.services'

const formSchema = ForgotPasswordFormSchema

const formFields = [{ name: 'email', label: 'Email', type: 'email', autoFocus: true }]

function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [messageVariant, setMessageVariant] = useState<'default' | 'destructive'>('destructive')

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setLoading(true)

      const response = await resetPasswordApi(values)
      const { success } = response.data

      if (!success) {
        setMessageVariant('destructive')
        setErrorMessage('Your password could not be reset. Please try again.')
        return
      }

      setMessageVariant('default')
      setErrorMessage(
        'A password reset link has been sent to your email. Please check your inbox and follow the instructions.'
      )
    } catch (e: any) {
      console.log(e)
      setMessageVariant('destructive')
      setErrorMessage(e?.message ?? GENERIC_ERROR_MESSAGE)
    } finally {
      setLoading(false)
    }
  }

  function renderForm() {
    return formFields.map(({ name, label, ...formField }) => (
      <FormField
        key={name}
        control={form.control}
        name={name as any}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{label}</FormLabel>

            <FormControl>
              <Input disabled={loading} {...field} {...formField} />
            </FormControl>

            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />
    ))
  }

  return (
    <>
      <Heading level={1}>Forgot your password?</Heading>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
          {renderForm()}

          {errorMessage && (
            <Alert variant={messageVariant}>
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}

          <Button type="submit" className="w-full" loading={loading} disabled={loading}>
            Reset Your Password
          </Button>

          <Button outline href={RouteNames.Login}>
            Back to Login
          </Button>
        </form>
      </Form>
    </>
  )
}

export default ForgotPasswordPage
