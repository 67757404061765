import { HeadsetIcon } from 'lucide-react'
import { Button } from './catalyst/button'
import { Heading } from './catalyst/heading'

const NotFound = () => {
  return (
    <div className="space-y-4">
      <p className="text-base font-semibold text-sky-400">404</p>
      <Heading level={1} description="Sorry, we couldn’t find the page you’re looking for">
        Page not found
      </Heading>

      <div className="space-x-4">
        <Button href="/">Go back home</Button>
        <Button href="/" plain>
          Support <HeadsetIcon size={18} />
        </Button>
      </div>
    </div>
  )
}

export default NotFound
