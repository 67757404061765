import { Badge } from 'components/catalyst/badge'
import { Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownMenu } from 'components/catalyst/dropdown'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/catalyst/table'
import { EllipsisVertical } from 'lucide-react'
import { useAuthStore } from 'modules/auth'

export function ApiKeysTable() {
  const apiKey = useAuthStore((state) => state.apiKey)

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader>ID</TableHeader>
          <TableHeader>NAME</TableHeader>
          <TableHeader>API KEY</TableHeader>
          <TableHeader>ACTIONS</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* {MOCK_API_KEYS.map((item) => ( */}
        <TableRow>
          <TableCell className="font-medium">
            <code>#</code>
          </TableCell>
          <TableCell className="text-zinc-500 dark:text-zinc-100">Master API Key</TableCell>
          <TableCell>
            <Badge>
              <code>{apiKey}</code>
            </Badge>
          </TableCell>
          <TableCell className="space-x-4">
            <Dropdown>
              <DropdownButton plain>
                <EllipsisVertical size={16} />
              </DropdownButton>
              <DropdownMenu>
                <DropdownItem href="/users/1">View</DropdownItem>
                <DropdownItem href="/users/1/edit">Edit</DropdownItem>
                <DropdownDivider />
                <DropdownItem>Delete</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </TableCell>
        </TableRow>
        {/* ))} */}
      </TableBody>
    </Table>
  )
}
