import { AppBgImage } from './app-bg-image'
import { Text } from './catalyst/text'
import { Logo } from './logo'

type Props = {
  children: React.ReactNode
}

export function AuthLayout(props: Props) {
  return (
    <div className="relative isolate flex min-h-svh w-full bg-white max-lg:flex-col lg:bg-zinc-100 dark:bg-zinc-950">
      <div className="flex min-h-full flex-1 flex-col px-6 py-12 lg:px-8">
        <div className="mb-12 flex items-center justify-center">
          <Logo disabled className="scale-[1.35]" />
        </div>
        <div className="relative mx-auto w-full max-w-lg overflow-hidden rounded-xl bg-white shadow-[0px_0px_0px_1px_rgba(9,9,11,0.07),0px_2px_2px_0px_rgba(9,9,11,0.05)] dark:bg-zinc-900 dark:shadow-[0px_0px_0px_1px_rgba(255,255,255,0.1)] dark:before:pointer-events-none dark:before:absolute dark:before:-inset-px dark:before:rounded-xl dark:before:shadow-[0px_2px_8px_0px_rgba(0,_0,_0,_0.20),_0px_1px_0px_0px_rgba(255,_255,_255,_0.06)_inset] forced-colors:outline">
          <div className="relative z-20 grid w-full place-items-start space-y-6 overflow-hidden p-6 py-8 backdrop-blur-sm sm:p-8 lg:p-12">
            {props.children}
          </div>
        </div>

        <Text className="mt-12 text-center opacity-50">© {new Date().getFullYear()} Vectroid, Inc.</Text>
      </div>

      <AppBgImage />
    </div>
  )
}
