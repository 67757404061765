import { useEffect } from 'react'
import { IndexesHeader } from '../components/indexes-header'
import { IndexesHeaderToolbar } from '../components/indexes-header-toolbar'
import { IndexListView } from '../containers/index-list-view'
import { useIndexStore } from '../store'

function IndexesPage() {
  const { indexes, loading, fetchIndexes } = useIndexStore()

  useEffect(() => {
    fetchIndexes()
  }, [])

  return (
    <>
      <IndexesHeader />
      <IndexesHeaderToolbar />
      <IndexListView indexes={indexes} loading={loading} />
    </>
  )
}

export default IndexesPage
