import { jsonRpcApiClient } from 'lib/index'
import { JSONRPCMethods } from 'types/json-rpc.types'
import { LoginResponse, ResetPasswordRequest, ResetPasswordResponse, SetupNewPasswordRequest } from '../types'

export function resetPasswordApi(values: ResetPasswordRequest) {
  return jsonRpcApiClient<ResetPasswordRequest, ResetPasswordResponse>(JSONRPCMethods.RESET_PASSWORD, values)
}

export function setupNewPassword(values: SetupNewPasswordRequest) {
  return jsonRpcApiClient<SetupNewPasswordRequest, LoginResponse>(JSONRPCMethods.SETUP_NEW_PASSWORD, values)
}
