import { DashboardLayout, useTheme } from 'context/theme-provider'
import { Index } from 'modules/indexes'
import { IndexListEmptyState } from '../components/index-list-empty-state'
import { IndexesGridLayout } from '../components/indexes-grid-layout'
import { IndexesStackLayout } from '../components/indexes-stack-layout'

type Props = {
  loading: boolean
  indexes: Index[]
}

export function IndexListView(props: Props) {
  const { dashboardLayout } = useTheme()

  if (!props.loading && props.indexes.length === 0) {
    return <IndexListEmptyState />
  }

  if (dashboardLayout === DashboardLayout.Grid) {
    return <IndexesGridLayout {...props} />
  }

  return <IndexesStackLayout {...props} />
}
