import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import { Input } from 'components/catalyst/input'
import { Alert, AlertDescription } from 'components/ui/alert'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { GENERIC_ERROR_MESSAGE } from 'const/error-messages'
import { RouteNames } from 'const/navigation'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ResetPasswordFormSchema } from '../schema/reset-password.schema'
import { setupNewPassword } from '../services/reset-password.services'
import { useAuthStore } from '../store'

const formSchema = ResetPasswordFormSchema

const formFields = [
  { name: 'password', label: 'Password', type: 'password', autoFocus: true },
  { name: 'passwordConfirmation', label: 'Confirm Password', type: 'password' },
]

function ResetPasswordPage() {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const loginAction = useAuthStore((state) => state.login)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const resetToken = searchParams.get('token')

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      setLoading(true)

      if (values.password !== values.passwordConfirmation) {
        setErrorMessage('Passwords do not match')
        return
      }

      if (!resetToken) {
        setErrorMessage('Invalid token')
        return
      }

      const response = await setupNewPassword({
        password: values.password,
        token: resetToken,
      })
      const { token, refreshToken, apiKey, account } = response.data

      loginAction(token, refreshToken, apiKey, account)
      navigate(RouteNames.Dashboard)
    } catch (e: any) {
      console.log(e)
      setErrorMessage(e?.message ?? GENERIC_ERROR_MESSAGE)
    } finally {
      setLoading(false)
    }
  }

  function renderForm() {
    return formFields.map(({ name, label, ...formField }) => (
      <FormField
        key={name}
        control={form.control}
        name={name as any}
        render={({ field }) => (
          <FormItem>
            <FormLabel>{label}</FormLabel>

            <FormControl>
              <Input disabled={loading} {...field} {...formField} />
            </FormControl>

            <FormMessage className="text-xs" />
          </FormItem>
        )}
      />
    ))
  }

  return (
    <>
      <Heading level={1}>Reset Password</Heading>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
          {renderForm()}

          {errorMessage && (
            <Alert variant="destructive">
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}

          <Button type="submit" className="w-full" loading={loading} disabled={loading}>
            Reset Password & Login
          </Button>
          <Button outline href={RouteNames.Login}>
            Back to Login
          </Button>
        </form>
      </Form>
    </>
  )
}

export default ResetPasswordPage
