export enum AccountVerificationStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
}

export enum AuthMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
}
