import { useNotificationStore } from 'components/common'
import { useCallback, useState } from 'react'

interface UseAsyncDataReturn<T> {
  data: T | null
  loading: boolean
  fetchData: () => Promise<void>
}

interface Options<T> {
  onDataFetched?: (data: T) => void
  showErrorMessage?: boolean
}

export function useAsyncData<T>(apiFunction: () => Promise<T>, options?: Options<T>): UseAsyncDataReturn<T> {
  const [loading, setLoading] = useState(false)
  const { reset, setMessage } = useNotificationStore()

  const fetchData = useCallback(async () => {
    setLoading(true)
    reset()
    try {
      const response = await apiFunction()
      options?.onDataFetched?.(response)
    } catch (error: any) {
      options?.showErrorMessage &&
        setMessage(error?.message, {
          title: 'Failed to fetch data',
          type: 'error',
          actionBtns: [{ label: 'Retry', onClick: fetchData }],
        })
    } finally {
      setLoading(false)
    }
  }, [apiFunction, reset, setMessage])

  return { data: null, loading, fetchData }
}
