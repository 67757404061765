import { Card } from 'components/catalyst/card'
import { Subheading } from 'components/catalyst/heading'
import { Text } from 'components/catalyst/text'
import { useContext } from 'react'
import { IndexDetailContext } from '../context'

export function IndexDetailInfo() {
  const indexDetail = useContext(IndexDetailContext)

  return (
    <Card className="h-auto">
      <div className="grid grid-cols-3 gap-12">
        <div className="col-span-2 lg:col-span-1">
          <Subheading level={6}>Name</Subheading>
          <Text>{indexDetail.name}</Text>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <Subheading level={6}>Cloud</Subheading>
          <Text className="uppercase">{indexDetail.cloud}</Text>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <Subheading level={6}>Region</Subheading>
          <Text className="flex items-center">
            <span className="mr-1.5 block h-1.5 w-1.5 rounded-full bg-emerald-400" />
            {indexDetail.region}
          </Text>
        </div>
        <div className="col-span-2 lg:col-span-1">
          <Subheading level={6}>Similarity Function</Subheading>
          <Text>cosine</Text>
        </div>
        <div className="col-span-2 lg:col-span-2">
          <Subheading level={6}>Endpoint</Subheading>
          <Text>joy-27353871.api.vectroid.com</Text>
        </div>
      </div>
    </Card>
  )
}
