export const OCamlCohttpSnippet = `open Cohttp_lwt_unix
open Cohttp
open Lwt

let uri = Uri.of_string "{{url}}" in
let headers = Header.add_list (Header.init ()) [
  ("x-api-key", "{{apiKey}}");
  ("Accept", "application/json");
  ("Content-Type", "application/json");
] in
let body = Cohttp_lwt_body.of_string "{{body}}" in

Client.call ~headers ~body \`POST uri
>>= fun (res, body_stream) ->
  (* Do stuff with the result *)`
