import { Collection } from 'modules/indexes'

export enum CollectionType {
  CREATE_INDEX = 'create_index',
  DESCRIBE_INDEX = 'describe_index',
  LIST_INDEXES = 'list_indexes',
  DROP_INDEX = 'drop_index',
  INDEX_VECTOR = 'index_vector',
  SEARCH_VECTOR = 'search_vector',
}

export const API_COLLECTION_DATA: { [key in CollectionType]: Collection } = {
  [CollectionType.CREATE_INDEX]: {
    color: 'amber',
    method: 'POST',
    label: 'Create Index',
    endpoint: '/indexes',
    type: CollectionType.CREATE_INDEX,
    snippets: {
      json: {
        indexName: '{{indexName}}',
        similarityFunction: 'cosine',
        cloud: 'gcp',
        region: 'us-east1',
      },
    },
  },
  [CollectionType.DESCRIBE_INDEX]: {
    color: 'amber',
    method: 'POST',
    label: 'Describe Index',
    endpoint: '/describe',
    type: CollectionType.DESCRIBE_INDEX,
    snippets: {
      json: {
        indexName: '{{indexName}}',
      },
    },
  },
  [CollectionType.LIST_INDEXES]: {
    color: 'green',
    method: 'GET',
    label: 'List Indexes',
    endpoint: '/indexes',
    disabled: true,
    type: CollectionType.LIST_INDEXES,
    snippets: {
      json: {},
    },
  },
  [CollectionType.DROP_INDEX]: {
    color: 'red',
    method: 'POST',
    label: 'Drop Index',
    endpoint: '/drop',
    type: CollectionType.DROP_INDEX,
    disabled: true,
  },
  [CollectionType.INDEX_VECTOR]: {
    color: 'amber',
    method: 'POST',
    label: 'Insert Data',
    endpoint: '/index',
    type: CollectionType.INDEX_VECTOR,
    snippets: {
      json: {
        indexName: '{{indexName}}',
        id: '5',
        vector: [51, 53, 55],
      },
    },
  },
  [CollectionType.SEARCH_VECTOR]: {
    color: 'amber',
    method: 'POST',
    label: 'Search Data',
    endpoint: '/query',
    type: CollectionType.SEARCH_VECTOR,
    snippets: {
      json: {
        indexName: '{{indexName}}',
        vector: [1, 3, 7],
        k: 10,
      },
    },
  },
}
