function ErrorBoundaryFallback() {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>Something went wrong</pre>
    </div>
  )
}

export default ErrorBoundaryFallback
