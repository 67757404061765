import { Banner } from 'components/banner'
import { Button } from 'components/catalyst/button'
import { Heading } from 'components/catalyst/heading'
import Spinner from 'components/spinner'
import { RouteNames } from 'const/navigation'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { verifyAccountApi } from '../services/verify-account.services'
import { useAuthStore } from '../store'
import { VerifyAccountRequest } from '../types'

function VerifyAccountPage() {
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [searchParams] = useSearchParams()
  const loginAction = useAuthStore((state) => state.login)
  const navigate = useNavigate()

  const verificationToken = searchParams.get('token')

  async function handleAccountVerification() {
    if (!verificationToken) {
      setErrorMessage('Token not found')
      setLoading(false)
      return
    }

    try {
      const request: VerifyAccountRequest = {
        token: verificationToken,
      }
      const response = await verifyAccountApi(request)

      const { token, refreshToken, apiKey, account } = response.data

      loginAction(token, refreshToken, apiKey, account)
      navigate(RouteNames.Dashboard)
    } catch (error: any) {
      console.error(error)
      setErrorMessage(error.message)
      setLoading(false)
    }
  }

  function backToLogin() {
    navigate(RouteNames.Logout)
  }

  useEffect(() => {
    handleAccountVerification()
  }, [verificationToken])

  return (
    <>
      <Heading level={1}>
        <span className="flex items-center">Verifying your account...</span>
      </Heading>

      {loading && <Spinner />}
      {errorMessage && (
        <div className="w-full">
          <Banner title="Failed to verify your account" message={errorMessage} type="error" />
          <Button onClick={backToLogin}>Back to Login</Button>
        </div>
      )}
    </>
  )
}

export default VerifyAccountPage
