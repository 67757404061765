export const KotlinOkHttpSnippet = `val client = OkHttpClient()

val mediaType = MediaType.parse("text/plain")
val body = RequestBody.create(mediaType, "{{body}}")
val request = Request.Builder()
  .url("{{url}}")
  .post(body)
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .addHeader("Content-Type", "application/json")
  .build()

val response = client.newCall(request).execute()`
