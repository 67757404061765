export const PowershellInvokeRestMethodSnippet = `$headers=@{}
$headers.Add("x-api-key", "{{apiKey}}")
$headers.Add("Accept", "application/json")
$headers.Add("Content-Type", "application/json")
$response = Invoke-RestMethod -Uri '{{url}}' -Method POST -Headers $headers -ContentType 'application/json' -Body '{{body}}'`

export const PowershellInvokeWebRequestSnippet = `$headers=@{}
$headers.Add("x-api-key", "{{apiKey}}")
$headers.Add("Accept", "application/json")
$headers.Add("Content-Type", "application/json")
$response = Invoke-WebRequest -Uri '{{url}}' -Method POST -Headers $headers -ContentType 'application/json' -Body '{{body}}'`
