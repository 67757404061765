import { githubDark, githubLight } from '@uiw/codemirror-theme-github'
import { Theme } from 'context/theme-provider'

export function getCodeEditorTheme(theme: Theme) {
  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

  if (theme === 'system') {
    return systemTheme === 'dark' ? githubDark : githubLight
  }

  return theme === 'dark' ? githubDark : githubLight
}
