import { Badge } from 'components/catalyst/badge'
import { Button } from 'components/catalyst/button'
import { Play } from 'lucide-react'
import { useAuthStore } from 'modules/auth'
import { Collection } from 'modules/indexes'

type Props = {
  executeCode: () => void
  loading: boolean
  activeCollection: Collection
}

export function ConsoleHeader(props: Props) {
  const endpoint = useAuthStore((state) => state.account?.endpoint)

  const httpUrl = `https://${endpoint}.api.vectroid.com/api/v1${props.activeCollection.endpoint}`

  return (
    <>
      <div className="mb-4 flex rounded-lg border bg-white dark:bg-zinc-950">
        <Badge
          className="justify-center !rounded-r-none border-r !px-3 !py-2 font-medium"
          color={props.activeCollection.color as any}
        >
          {props.activeCollection.method}
        </Badge>

        <span className="flex h-[40px] flex-1 items-center px-3 text-sm text-zinc-700 dark:text-zinc-300">
          {httpUrl}
        </span>

        <Button
          color="amber"
          className="!rounded-l-none !px-4"
          onClick={() => props.executeCode()}
          loading={props.loading}
        >
          <Play size={16} strokeWidth={2.5} />
          Run
        </Button>
      </div>
    </>
  )
}
