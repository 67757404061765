export const JavaAsyncHTTPClientSnippet = `AsyncHttpClient client = new DefaultAsyncHttpClient();
client.prepare("POST", "{{url}}")
  .setHeader("x-api-key", "{{apiKey}}")
  .setHeader("Accept", "application/json")
  .setHeader("Content-Type", "application/json")
  .setBody("{{body}}")
  .execute()
  .toCompletableFuture()
  .thenAccept(System.out::println)
  .join();

client.close();`

export const JavaNetHttpSnippet = `HttpRequest request = HttpRequest.newBuilder()
.uri(URI.create("{{url}}"))
    .header("x-api-key", "{{apiKey}}")
    .header("Accept", "application/json")
    .header("Content-Type", "application/json")
    .method("POST", HttpRequest.BodyPublishers.ofString("{{body}}"))
    .build();
HttpResponse<String> response = HttpClient.newHttpClient().send(request, HttpResponse.BodyHandlers.ofString());
System.out.println(response.body());`

export const JavaOkHttpSnippet = `OkHttpClient client = new OkHttpClient();

MediaType mediaType = MediaType.parse("text/plain");
RequestBody body = RequestBody.create(mediaType, "{{body}}");
Request request = new Request.Builder()
  .url("{{url}}")
  .post(body)
  .addHeader("x-api-key", "{{apiKey}}")
  .addHeader("Accept", "application/json")
  .addHeader("Content-Type", "application/json")
  .build();

Response response = client.newCall(request).execute();`

export const JavaUnirestSnippet = `HttpResponse<String> response = Unirest.post("{{url}}")
  .header("x-api-key", "{{apiKey}}")
  .header("Accept", "application/json")
  .header("Content-Type", "application/json")
  .body("{{body}}")
  .asString();`
