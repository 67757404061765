import { GLOBAL_API_PLAYGROUND_COLLECTION } from 'const'
import { create } from 'zustand'
import { Collection } from '../types'

type State = {
  activeCollection: Collection
}

type Actions = {
  setActiveCollection: (collection: Collection) => void
}

export const useConsoleStore = create<State & Actions>((set) => ({
  activeCollection: GLOBAL_API_PLAYGROUND_COLLECTION[0].items[0],
  setActiveCollection: (collection: Collection) => set({ activeCollection: collection }),
}))
