import { z } from 'zod'

const pwdSchema = z
  .string()
  .min(8, { message: 'Password must be at least 8 characters long' })
  .max(50, { message: 'Password cannot exceed 50 characters' })
  .regex(/[a-z]/, { message: 'Password must include a lowercase letter' })
  .regex(/[A-Z]/, { message: 'Password must include an uppercase letter' })
  .regex(/[0-9]/, { message: 'Password must include a number' })
  .regex(/[^a-zA-Z0-9]/, { message: 'Password must include a special character' })

export const ResetPasswordFormSchema = z.object({
  password: pwdSchema,
  passwordConfirmation: pwdSchema,
})
