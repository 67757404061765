import { ClojureSnippet } from './snippets/clojure'
import { CSharpHttpClientSnippet, CSharpRestSharpSnippet } from './snippets/csharp'
import { CurlSnippet } from './snippets/curl'
import { GoSnippet } from './snippets/go'
import { HttpRequestStringSnippet } from './snippets/http'
import { JavaAsyncHTTPClientSnippet, JavaNetHttpSnippet, JavaOkHttpSnippet, JavaUnirestSnippet } from './snippets/java'
import {
  JavascriptAxiosSnippet,
  JavascriptFetchSnippet,
  JavascriptJQuerySnippet,
  JavaScriptXMLHttpRequestSnippet,
} from './snippets/javascript'
import { KotlinOkHttpSnippet } from './snippets/kotlin'
import { OCamlCohttpSnippet } from './snippets/ocaml'
import { PHPCurlSnippet } from './snippets/php'
import { PowershellInvokeRestMethodSnippet, PowershellInvokeWebRequestSnippet } from './snippets/powershell'
import { PythonNativeSnippet, PythonRequestsSnippet } from './snippets/python'
import { RHttrSnippet } from './snippets/r'
import { RubyNativeSnippet } from './snippets/ruby'
import { ShellCurlSnippet, ShellHTTPieSnippet, ShellWgetSnippet } from './snippets/shell'
import { SwiftNSURLSessionSnippet } from './snippets/swift'

export enum CodeSnippetTemplateIds {
  Curl = 1,
  Clojure,
  CSharpHttpClient,
  CSharpRestSharp,
  Go,
  HttpRequestString,
  JavaAsyncHTTPClient,
  JavaNetHttp,
  JavaOkHttp,
  JavaUnirest,
  JavaScriptAxios,
  JavaScriptFetch,
  JavaScriptJQuery,
  JavaScriptXMLHttpRequest,
  KotlinOkHttp,
  OCamlCohttp,
  PHPCurl,
  PowershellInvokeRestMethod,
  PowershellInvokeWebRequest,
  PythonNative,
  PythonRequests,
  RHttr,
  RubyNative,
  ShellCurl,
  ShellHTTPie,
  ShellWget,
  SwiftNSURLSession,
}

export enum CodeLanguage {
  Curl = 'curl',
  Clojure = 'clojure',
  CSharp = 'csharp',
  Go = 'go',
  Java = 'java',
  JavaScript = 'javascript',
  Kotlin = 'kotlin',
  OCaml = 'OCaml',
  PHP = 'php',
  Powershell = 'powershell',
  Python = 'python',
  R = 'r',
  Ruby = 'ruby',
  Shell = 'shell',
  Swift = 'swift',
}

export type Snippet = {
  id: CodeSnippetTemplateIds
  label: string
  lang: CodeLanguage
}

export const CodeSnippetLanguages = [
  { id: CodeSnippetTemplateIds.Curl, label: 'C - cURL', lang: CodeLanguage.Curl },
  { id: CodeSnippetTemplateIds.Clojure, label: 'Clojure - clj-http', lang: CodeLanguage.Clojure },
  { id: CodeSnippetTemplateIds.CSharpHttpClient, label: 'C# - HttpClient', lang: CodeLanguage.CSharp },
  { id: CodeSnippetTemplateIds.CSharpRestSharp, label: 'C# - RestSharp', lang: CodeLanguage.CSharp },
  { id: CodeSnippetTemplateIds.Go, label: 'Go', lang: CodeLanguage.Go },
  { id: CodeSnippetTemplateIds.HttpRequestString, label: 'HTTP - HTTP 1.1 Request String', lang: CodeLanguage.Curl },
  { id: CodeSnippetTemplateIds.JavaAsyncHTTPClient, label: 'Java - AsyncHTTPClient', lang: CodeLanguage.Java },
  { id: CodeSnippetTemplateIds.JavaNetHttp, label: 'Java - java.net.http', lang: CodeLanguage.Java },
  { id: CodeSnippetTemplateIds.JavaOkHttp, label: 'Java - OkHttp', lang: CodeLanguage.Java },
  { id: CodeSnippetTemplateIds.JavaUnirest, label: 'Java - Unirest', lang: CodeLanguage.Java },
  { id: CodeSnippetTemplateIds.JavaScriptAxios, label: 'JavaScript - Axios', lang: CodeLanguage.JavaScript },
  { id: CodeSnippetTemplateIds.JavaScriptFetch, label: 'JavaScript - Fetch', lang: CodeLanguage.JavaScript },
  { id: CodeSnippetTemplateIds.JavaScriptJQuery, label: 'JavaScript - jQuery', lang: CodeLanguage.JavaScript },
  {
    id: CodeSnippetTemplateIds.JavaScriptXMLHttpRequest,
    label: 'JavaScript - XMLHttpRequest',
    lang: CodeLanguage.JavaScript,
  },
  { id: CodeSnippetTemplateIds.KotlinOkHttp, label: 'Kotlin - OkHttp', lang: CodeLanguage.Kotlin },
  { id: CodeSnippetTemplateIds.OCamlCohttp, label: 'OCaml - cohttp', lang: CodeLanguage.OCaml },
  { id: CodeSnippetTemplateIds.PHPCurl, label: 'PHP - cURL', lang: CodeLanguage.PHP },
  {
    id: CodeSnippetTemplateIds.PowershellInvokeRestMethod,
    label: 'Powershell - Invoke-RestMethod',
    lang: CodeLanguage.Powershell,
  },
  {
    id: CodeSnippetTemplateIds.PowershellInvokeWebRequest,
    label: 'Powershell - Invoke-WebRequest',
    lang: CodeLanguage.Powershell,
  },
  { id: CodeSnippetTemplateIds.PythonNative, label: 'Python - Python 3 Native', lang: CodeLanguage.Python },
  { id: CodeSnippetTemplateIds.PythonRequests, label: 'Python - Requests', lang: CodeLanguage.Python },
  { id: CodeSnippetTemplateIds.RHttr, label: 'R - httr', lang: CodeLanguage.R },
  { id: CodeSnippetTemplateIds.RubyNative, label: 'Ruby - Ruby Native', lang: CodeLanguage.Ruby },
  { id: CodeSnippetTemplateIds.ShellCurl, label: 'Shell - cURL', lang: CodeLanguage.Shell },
  { id: CodeSnippetTemplateIds.ShellHTTPie, label: 'Shell - HTTPie', lang: CodeLanguage.Shell },
  { id: CodeSnippetTemplateIds.ShellWget, label: 'Shell - Wget', lang: CodeLanguage.Shell },
  { id: CodeSnippetTemplateIds.SwiftNSURLSession, label: 'Swift - NSURLSession', lang: CodeLanguage.Swift },
]

export const Snippets = {
  [CodeSnippetTemplateIds.Curl]: CurlSnippet,
  [CodeSnippetTemplateIds.Clojure]: ClojureSnippet,
  [CodeSnippetTemplateIds.CSharpHttpClient]: CSharpHttpClientSnippet,
  [CodeSnippetTemplateIds.CSharpRestSharp]: CSharpRestSharpSnippet,
  [CodeSnippetTemplateIds.Go]: GoSnippet,
  [CodeSnippetTemplateIds.HttpRequestString]: HttpRequestStringSnippet,
  [CodeSnippetTemplateIds.JavaAsyncHTTPClient]: JavaAsyncHTTPClientSnippet,
  [CodeSnippetTemplateIds.JavaNetHttp]: JavaNetHttpSnippet,
  [CodeSnippetTemplateIds.JavaOkHttp]: JavaOkHttpSnippet,
  [CodeSnippetTemplateIds.JavaUnirest]: JavaUnirestSnippet,
  [CodeSnippetTemplateIds.JavaScriptFetch]: JavascriptFetchSnippet,
  [CodeSnippetTemplateIds.JavaScriptAxios]: JavascriptAxiosSnippet,
  [CodeSnippetTemplateIds.JavaScriptJQuery]: JavascriptJQuerySnippet,
  [CodeSnippetTemplateIds.JavaScriptXMLHttpRequest]: JavaScriptXMLHttpRequestSnippet,
  [CodeSnippetTemplateIds.KotlinOkHttp]: KotlinOkHttpSnippet,
  [CodeSnippetTemplateIds.OCamlCohttp]: OCamlCohttpSnippet,
  [CodeSnippetTemplateIds.PHPCurl]: PHPCurlSnippet,
  [CodeSnippetTemplateIds.PowershellInvokeRestMethod]: PowershellInvokeRestMethodSnippet,
  [CodeSnippetTemplateIds.PowershellInvokeWebRequest]: PowershellInvokeWebRequestSnippet,
  [CodeSnippetTemplateIds.PythonNative]: PythonNativeSnippet,
  [CodeSnippetTemplateIds.PythonRequests]: PythonRequestsSnippet,
  [CodeSnippetTemplateIds.RHttr]: RHttrSnippet,
  [CodeSnippetTemplateIds.RubyNative]: RubyNativeSnippet,
  [CodeSnippetTemplateIds.ShellCurl]: ShellCurlSnippet,
  [CodeSnippetTemplateIds.ShellHTTPie]: ShellHTTPieSnippet,
  [CodeSnippetTemplateIds.ShellWget]: ShellWgetSnippet,
  [CodeSnippetTemplateIds.SwiftNSURLSession]: SwiftNSURLSessionSnippet,
}
