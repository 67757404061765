import { IndexDetailInfo } from 'modules/indexes/components/index-detail-info'

function AccessDatabasePage() {
  return (
    <>
      <IndexDetailInfo />
    </>
  )
}

export default AccessDatabasePage
