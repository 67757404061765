import { Collection } from 'modules/indexes'
import { API_COLLECTION_DATA as API_OPERATIONS, CollectionType } from './api-collection-data'

export const GLOBAL_API_PLAYGROUND_COLLECTION: { title: string; items: Collection[] }[] = [
  {
    title: 'Index Operations',
    items: [
      API_OPERATIONS[CollectionType.CREATE_INDEX],
      API_OPERATIONS[CollectionType.DESCRIBE_INDEX],
      API_OPERATIONS[CollectionType.LIST_INDEXES],
      API_OPERATIONS[CollectionType.DROP_INDEX],
    ],
  },

  {
    title: 'Vector Operations',
    items: [API_OPERATIONS[CollectionType.INDEX_VECTOR], API_OPERATIONS[CollectionType.SEARCH_VECTOR]],
  },
]

export const INDEX_API_PLAYGROUND_COLLECTION: { title: string; items: Collection[] }[] = [
  {
    title: 'Index Operations',
    items: [API_OPERATIONS[CollectionType.DESCRIBE_INDEX], API_OPERATIONS[CollectionType.DROP_INDEX]],
  },

  {
    title: 'Vector Operations',
    items: [API_OPERATIONS[CollectionType.INDEX_VECTOR], API_OPERATIONS[CollectionType.SEARCH_VECTOR]],
  },
]
