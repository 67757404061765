import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from 'components/catalyst/dropdown'
import { cn } from 'lib/utils'
import { ChevronDown } from 'lucide-react'
import { ReactNode, useState } from 'react'
import { CodeLanguage, CodeSnippetLanguages, CodeSnippetTemplateIds, Snippet, Snippets } from '../const/code-snippets'

import { Dialog, DialogBody, DialogDescription, DialogTitle } from 'components/catalyst/dialog'
import { CodeEditor } from 'components/code-editor'
import { useNotificationStore } from 'components/common'
import { useAuthStore } from 'modules/auth'
import { useConsoleStore } from 'modules/indexes'
import { replaceStaticVariables } from '../utils/playground-utils'

type Props = {
  bodyObject: string
}

const isEscapeCharBodyLang = (snippet: CodeSnippetTemplateIds) =>
  [
    CodeSnippetTemplateIds.Curl,
    CodeSnippetTemplateIds.Clojure,
    CodeSnippetTemplateIds.CSharpHttpClient,
    CodeSnippetTemplateIds.CSharpRestSharp,
    CodeSnippetTemplateIds.Go,
    CodeSnippetTemplateIds.JavaAsyncHTTPClient,
    CodeSnippetTemplateIds.JavaNetHttp,
    CodeSnippetTemplateIds.JavaOkHttp,
    CodeSnippetTemplateIds.JavaUnirest,
    CodeSnippetTemplateIds.KotlinOkHttp,
    CodeSnippetTemplateIds.OCamlCohttp,
    CodeSnippetTemplateIds.PHPCurl,
    CodeSnippetTemplateIds.PythonNative,
    CodeSnippetTemplateIds.RHttr,
    CodeSnippetTemplateIds.RubyNative,
  ].includes(snippet)

export function CodeGenerator(props: Props) {
  const [selectedSnippet, setSelectedSnippet] = useState<Snippet | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [generatedCode, setGeneratedCode] = useState('')
  const setMessage = useNotificationStore((state) => state.setMessage)

  const { account, apiKey } = useAuthStore()
  const { activeCollection } = useConsoleStore()

  const handleItemClick = (item: Snippet) => {
    try {
      const url = `https://${account!.endpoint}.api.vectroid.com/api/v1${activeCollection.endpoint}`
      const parsedBody = JSON.stringify(JSON.parse(props.bodyObject))
      const snippet = Snippets[item.id as keyof typeof Snippets]
      const codeSnippet = replaceStaticVariables(snippet, {
        url,
        apiKey: apiKey!,
        body: isEscapeCharBodyLang(item.id) ? parsedBody.replaceAll('"', '\\"') : parsedBody,
        endpoint: `/api/v1${activeCollection.endpoint}`,
        host: `${account!.endpoint}.api.vectroid.com`,
        contentLength: parsedBody.length.toString(),
      })
      setGeneratedCode(codeSnippet)
      setSelectedSnippet(item)
      setIsOpen(true)
    } catch (e: any) {
      console.log(e)
      setMessage(e.message, { type: 'error' })
    }
  }

  const renderItems = () => {
    return CodeSnippetLanguages.map((item) => (
      <DropdownItem
        onClick={() => {
          handleItemClick(item)
        }}
        key={item.id}
      >
        <img
          src={`${process.env.PUBLIC_URL}/static/icons/lang/${item.lang}.svg`}
          alt="icon"
          title={`${item.label} icon`}
          width="22"
          className={cn('mr-4', {
            'dark:invert dark:filter': [CodeLanguage.Curl, CodeLanguage.Shell].includes(item.lang),
          })}
        />
        {item.label}
      </DropdownItem>
    ))
  }

  const dropdown = (children: ReactNode, classes?: string) => (
    <Dropdown>
      <DropdownButton outline className={classes}>
        {children}
        <ChevronDown size={16} />
      </DropdownButton>

      <DropdownMenu anchor="bottom start">{renderItems()}</DropdownMenu>
    </Dropdown>
  )

  return (
    <div className="flex justify-end">
      {dropdown(
        <>
          <img
            src={`${process.env.PUBLIC_URL}/static/icons/lang/json.svg`}
            alt="icon"
            title={`JSON icon`}
            width="16"
            className={'dark:invert dark:filter'}
          />
          JSON
        </>,
        '!px-2 !py-1.5 !text-xs'
      )}

      {selectedSnippet && (
        <Dialog open={isOpen} onClose={setIsOpen} className="w-[860px] !max-w-full">
          <DialogTitle>Generate Client Code</DialogTitle>
          <DialogDescription>
            {dropdown(
              <span className="flex flex-1">
                <img
                  src={`${process.env.PUBLIC_URL}/static/icons/lang/${selectedSnippet?.lang}.svg`}
                  alt="icon"
                  title={`${selectedSnippet?.label} icon`}
                  width="24"
                  className={cn('mr-2', {
                    'dark:invert dark:filter': [CodeLanguage.Curl, CodeLanguage.Shell].includes(selectedSnippet.lang),
                  })}
                />
                {selectedSnippet?.label}
              </span>,
              'w-full !justify-start'
            )}
          </DialogDescription>
          <DialogBody>
            <CodeEditor code={generatedCode} />
          </DialogBody>
        </Dialog>
      )}
    </div>
  )
}
