export const RHttrSnippet = `library(httr)

url <- "{{url}}"

payload <- "{{body}}"

encode <- "raw"

response <- VERB("POST", url, body = payload, add_headers('x-api-key' = '{{apiKey}}'), content_type("text/plain"), accept("application/json"), encode = encode)

content(response, "text")`
