export const RubyNativeSnippet = `require 'uri'
require 'net/http'

url = URI("{{url}}")

http = Net::HTTP.new(url.host, url.port)
http.use_ssl = true

request = Net::HTTP::Post.new(url)
request["x-api-key"] = '{{apiKey}}'
request["Accept"] = 'application/json'
request["Content-Type"] = 'application/json'
request.body = "{{body}}"

response = http.request(request)
puts response.read_body`
