import { FileText as DocumentTextIcon, Key, Layers, Settings as Cog6ToothIcon, Terminal } from 'lucide-react'
import { appConfig } from './app-config'
import { RouteNames } from './navigation'

export const SidebarMenuItems = [
  [
    { text: 'Indexes', href: RouteNames.Indexes, icon: <Layers size={16} /> },
    { text: 'API Keys', href: RouteNames.ApiKeys, icon: <Key size={16} /> },
    { text: 'API Playground', href: RouteNames.ApiPlayground, icon: <Terminal size={16} /> },
  ],
  { spacer: true },
  [
    {
      text: 'API Docs',
      href: appConfig.DOCS_BASE_URL,
      icon: <DocumentTextIcon size={16} />,
      onSelect: () => {
        window.open(appConfig.DOCS_BASE_URL)
      },
    },
    { text: 'Settings', href: RouteNames.Settings, icon: <Cog6ToothIcon size={16} /> },
  ],
]
