import { Card } from './catalyst/card'
import { TableCell, TableRow } from './catalyst/table'
import { Skeleton } from './ui/skeleton'

export function CodeSkeleton() {
  return (
    <div className="space-y-4 p-2">
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-64" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-4" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-64" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-32" />
      <Skeleton className="h-2 w-12" />
      <Skeleton className="h-2 w-8" />
      <Skeleton className="h-2 w-4" />
    </div>
  )
}

export function IndexGridItemSkeleton() {
  return (
    <Card className="space-y-4">
      <Skeleton className="h-2 w-20" />
      <Skeleton className="h-20" />
      <div className="flex justify-between">
        <Skeleton className="h-3 w-32" />
        <Skeleton className="h-3 w-16 opacity-30" />
      </div>
      <Skeleton className="h-1 w-52" />
    </Card>
  )
}

export function IndexStackedItemSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton className="h-4 w-16" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-48" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-24" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-24" />
      </TableCell>
      <TableCell>
        <Skeleton className="h-4 w-12" />
      </TableCell>
    </TableRow>
  )
}
