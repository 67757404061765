export const GoSnippet = `package main

import (
	"fmt"
	"strings"
	"net/http"
	"io"
)

func main() {

	url := "{{url}}"

	payload := strings.NewReader("{{body}}")

	req, _ := http.NewRequest("POST", url, payload)

	req.Header.Add("x-api-key", "{{apiKey}}")
	req.Header.Add("Accept", "application/json")
	req.Header.Add("Content-Type", "application/json")

	res, _ := http.DefaultClient.Do(req)

	defer res.Body.Close()
	body, _ := io.ReadAll(res.Body)

	fmt.Println(res)
	fmt.Println(string(body))

}`
