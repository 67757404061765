import { RouteObject } from 'react-router-dom'

import { RouteNames } from 'const/navigation'
import APIPlaygroundPage from './containers/api-playground'

const routes: RouteObject[] = [
  {
    path: RouteNames.ApiPlayground,
    element: <APIPlaygroundPage />,
  },
]

export default routes
